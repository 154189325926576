import React, {Component} from 'react';

import PublicationService from '../../api/PublicationService.js';
import ReaderService from '../../api/ReaderService.js';

import PublicationArticleSectionComponent from './sections/PublicationArticleSectionComponent';
import PublicationSingleSectionComponent from './sections/PublicationSingleSectionComponent';
import PublicationRecentSectionComponent from './sections/PublicationRecentSectionComponent';
import ThemeComponent from '../util/ThemeComponent';

class HomeComponent extends Component {

    constructor(props){
        super(props);

        this.state = {
            title : null, 
            main: {
                title: "",
                regionPageCaption: "",
                regionPageLink: "",
                newsletterLink: "",
                articleDomain: "",
                recentArticlesLinkRegion: "",
                recentArticlesLinkNewspaper: "",
                pub: null,
                annex: null,
                sections: null
            },
            width: window.innerWidth,
            error: false,
            noSubscriberError: false,
            loggedInError: false,
            premiumAccessError: false,
        }
        this.getMostRecentPublication = this.getMostRecentPublication.bind(this);
        this.getPublication = this.getPublication.bind(this);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this)
    }

    render() {    
        return (
            <section className="publication-detail-section">
                <div className="publication-detail-section__header-wrapper">
                    <div className="publication-detail-section__header-wrapper__container">
                        <h1 className="heading-primary">{this.state.main.title}</h1>
                    </div>
                </div>

                {this.state.main.newsletterLink.trim() !== "" &&
                    <div className="newsletter-wrapper">
                        <div className="newsletter-wrapper__container">
                            <a className="newsletter-link" target="_blank" rel="noopener noreferrer" href={this.state.main.newsletterLink}>Schrijf je in voor de nieuwsbrief</a>
                        </div>
                    </div>
                }

                {this.state.main.pub !== null &&
                    <div className="row">
                        <ThemeComponent theme={this.state.main.pub.theme}/>
                        <div className="col-7-of-10">
                            <PublicationSingleSectionComponent pub={this.state.main.pub} loggedInError={this.state.loggedInError} premiumAccessError={this.state.premiumAccessError} 
                            subscriberError={this.state.noSubscriberError} noPubs={this.state.noPubs} sendToReader={this.sendToReader.bind(this)}/>
                        </div>
                        
                        {this.state.main.recentArticlesLinkRegion.trim() === "" &&
                            <div className="col-3-of-10">
                                <PublicationRecentSectionComponent pub={this.state.main.pub} getPublication={this.getPublication.bind(this)} error={this.state.error}></PublicationRecentSectionComponent>
                            </div>
                        }

                        {this.state.main.recentArticlesLinkRegion.trim() !== "" &&
                            <div className="col-3-of-10">
                                <h2 className="heading-secondary"><a className="regionCaption-link" target="_blank" rel="noopener noreferrer" href={this.state.main.regionPageLink}>{this.state.main.regionPageCaption}</a></h2>
                                <PublicationArticleSectionComponent regionPageLink={this.state.main.regionPageLink} articleDomain={this.state.main.articleDomain} recentArticlesLinkRegion={this.state.main.recentArticlesLinkRegion} recentArticlesLinkNewspaper={this.state.main.recentArticlesLinkNewspaper} error={this.state.error}></PublicationArticleSectionComponent>
                                <PublicationRecentSectionComponent pub={this.state.main.pub} getPublication={this.getPublication.bind(this)} error={this.state.error}></PublicationRecentSectionComponent>
                            </div>
                        } 
                    </div> 
                }

                {this.state.main.newsletterLink.trim() !== "" &&
                    <div className="newsletter-wrapper-bottom">
                        <div className="newsletter-wrapper-bottom__container">
                            <a className="newsletter-link-bottom" target="_blank" rel="noopener noreferrer" href={this.state.main.newsletterLink}>Schrijf je in voor de nieuwsbrief</a>
                        </div>
                    </div>
                }
            </section>
        )
    }

    componentDidMount(){
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.getMostRecentPublication();
    }
      
    componentWillUnmount() {
       window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    componentDidUpdate(prevProps, nextProps) {
        if(nextProps.match !== undefined){
            if (prevProps.match.params.title !== nextProps.match.params.title) {
                this.getMostRecentPublication();
            }
        }
    }

    handleWindowSizeChange(){
        this.setState({ width: window.innerWidth });
    };

    getMostRecentPublication(){
        PublicationService.getMostRecentPublication()
        .then(
            (response) => {
                if(response.data.publication == null){
                    this.setState({
                        error: false,
                        noSubscriberError: false,
                        premiumAccessError: false,
                        loggedInError: false,
                        main: {
                            title: response.data.title,
                            regionPageCaption: response.data.regionPageCaption,
                            regionPageLink: response.data.regionPageLink,
                            newsletterLink: response.data.newsletterLink,
                            articleDomain: response.data.articleDomain,
                            recentArticlesLinkRegion: response.data.recentArticlesLinkRegion,
                            recentArticlesLinkNewspaper: response.data.recentArticlesLinkNewspaper,
                            pub: null,
                            annex: null,
                            sections: null
                        },
                    })
                } else {
                    this.setState({
                        noPubs: false,
                        error: false,
                        noSubscriberError: false,
                        loggedInError: false,
                        premiumAccessError: false,
                        main: {
                            title: response.data.title,
                            regionPageCaption: response.data.regionPageCaption,
                            regionPageLink: response.data.regionPageLink,
                            newsletterLink: response.data.newsletterLink,
                            articleDomain: response.data.articleDomain,
                            recentArticlesLinkRegion: response.data.recentArticlesLinkRegion,
                            recentArticlesLinkNewspaper: response.data.recentArticlesLinkNewspaper,
                            pub: response.data.publication,
                            sections: response.data.publication.sections
                        }
                    }) 
                }
            }
        )
        .catch(
            (error) => {
                console.log(error);
                this.setState({
                    error: true,
                    noPubs: true,
                    noSubscriberError: false,
                    premiumAccessError: false,
                    loggedInError: false
                })
            }
        )
    }

    getPublication(pub){
        console.log(pub)
        if(pub == null){
            this.setState({
                noPubs: true,
                error: false,
                noSubscriberError: false,
                premiumAccessError: false,
                loggedInError: false,
                main: {
                    pub: null,
                    annex: null,
                    sections: null
                },
            })
        } else {
            this.setState({
                noPubs: false,
                error: false,
                noSubscriberError: false,
                premiumAccessError: false,
                loggedInError: false,
                main: {
                    title: this.state.main.title,
                    regionPageCaption: this.state.main.regionPageCaption,
                    regionPageLink: this.state.main.regionPageLink,
                    newsletterLink: this.state.main.newsletterLink,
                    articleDomain: this.state.main.articleDomain,
                    recentArticlesLinkRegion: this.state.main.recentArticlesLinkRegion,
                    recentArticlesLinkNewspaper: this.state.main.recentArticlesLinkNewspaper,
                    pub: pub,
                    sections: pub.sections
                }
            }) 
        }  
    }
 
    sendToReader(pub, readerUrl){
        ReaderService.sendToReader(pub.id, pub.titleShort, readerUrl, pub.publishedDate, pub.publishedWeekDay, pub.os)
        .then(
            (response) => {
                if(response.data.access){
                    window.location.href = response.data.readerUrl;
                } else {
                    this.setState({
                        premiumAccessError: response.data.noPremiumError,
                        loggedInError: response.data.notLoggedInError,
                        noSubscriberError: response.data.noSubscriberError
                    })
                }           
            }
        )
        .catch(
            (error) => {
                console.log(error)
            }
        )
    }

}   

export default HomeComponent;

