class CookieWallService {

    constructor() {
        this.cookieWall = {};
      }

    setCookieWallObject(cookieWall){
        this.cookieWall = cookieWall;
    }

    openPreferences(){
        this.cookieWall.preferences.show();
    }

    consentChanged(cwtToken){
        console.log("Cookie Toestemmingen aangepast");
    }
}

export default new CookieWallService();