import {NDC_PORTAL_PORT} from '../../constants';

class PublicationUtil {

    getPublicationName(){
        let hostName = window.location.hostname.split(".");
        if(hostName.length > 1){
            hostName = hostName[1];
        } else {
            hostName = hostName[0];
        }
        return hostName;
    }

    getHostName(){
        return window.location.protocol + '//' + window.location.hostname + ":" + NDC_PORTAL_PORT;
    }
}

export default new PublicationUtil();

