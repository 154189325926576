import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import HeaderComponent from './header/HeaderComponent';
import MainComponent from './main/MainComponent';
import FooterComponent from './footer/FooterComponent';

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname + window.location.search); // Record a pageview for the given page
});

function Portal() {
  return (
    <div className="portal">
        <Router>
          <HeaderComponent />
          <MainComponent />
          <FooterComponent />
        </Router>
    </div>
  );
}

export default Portal;
