import React from 'react';
import ReactDOM from 'react-dom';
import Portal from './components/Portal';
import * as serviceWorker from './serviceWorker';

import './stylesheets/main.scss';

ReactDOM.render(<Portal />, document.getElementById('portal'));

serviceWorker.unregister();
