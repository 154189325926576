import Axios from "axios";
import {AUTH_API_URL, AUTH_PREFIX, COOKIE_NAME, COOKIE_PATH, COOKIE_SECURE, COOKIE_EXPIRES, COOKIE_EVOLOK_SESSIONID} from '../constants'
import Cookies from 'js-cookie';
import PublicationUtil from '../components/util/PublicationUtil.js'


class AuthenticationService {

    constructor(){
        if(this.isUserLoggedIn()){
            this.setupAxiosInterceptors();
        }
    }

    getToken(){
        let token = Cookies.get(`${COOKIE_NAME}`);
        if(token !== undefined){
            return `${AUTH_PREFIX}` + token;
        }
        return null;
    }

    /****** Get sessionId cookie from evolok ******/
    getSessionId(){
        return Cookies.get(`${COOKIE_EVOLOK_SESSIONID}`);
    }

    saveToken(token){
        Cookies.set(`${COOKIE_NAME}`, token, {path: `${COOKIE_PATH}`, secure: (`${COOKIE_SECURE}` === "true"), sameSite: 'Strict', expires: parseInt(`${COOKIE_EXPIRES}`)});
        this.setupAxiosInterceptors();
    }

    isUserLoggedIn(){
        let token = Cookies.get(`${COOKIE_NAME}`);
        let sessionId = Cookies.get(`${COOKIE_EVOLOK_SESSIONID}`)
        if(token !== undefined && sessionId !== undefined){
            return true;
        }
        return false;
    }

    logout(){
        this.deleteToken()
            .finally(() => {
                Cookies.remove(`${COOKIE_NAME}`);
                Cookies.remove(`${COOKIE_EVOLOK_SESSIONID}`);
            })
    }

    /***** API Calls To Backend *****/
    authenticate(email, password){
        return Axios.post(`${PublicationUtil.getHostName() + AUTH_API_URL}`, {email, password});
    }

    authenticateBySessionId(sessionId){
        return Axios.post(`${PublicationUtil.getHostName() + AUTH_API_URL}`, {sessionId});
    }

    async renewToken(){
        try {
            let result = await Axios.put(`${PublicationUtil.getHostName()  + AUTH_API_URL}`, null, {withCredentials: true});
            console.log(result)
            this.saveToken(result.data.token);
        } catch(error){
            if(error.message !== "Request aborted"){
                console.log(error);
                this.logout();
            }
        }

    }

    async touchToken(){
        try {
            let result = await Axios.patch(`${PublicationUtil.getHostName()  + AUTH_API_URL}`, null, {withCredentials: true});
            this.saveToken(result.data.token);
        } catch(error){
            if(error.message !== "Request aborted"){
                console.log(error);
                this.logout();
            }
        }

    }

    deleteToken(){
        try {
            return Axios.delete(`${PublicationUtil.getHostName() + AUTH_API_URL}`, {withCredentials: true});
        } catch(error){
            console.log(error);
            if(error.message !== "Could not delete the session"){
                console.log("Session already deleted");
            }
        }
    }

    /***** Setup interceptors *****/
    setupAxiosInterceptors(){
        // request interceptor for adding jwt-token
        Axios.interceptors.request.use(
            (config) => {
                config.headers.authorization = this.getToken();
                return config;
            }
        )

        // response interceptor for deleting cookie when session can't be validated
        Axios.interceptors.response.use(
            (response) => {
               return response;
            }, (error) => {
               console.log(error);
               return Promise.reject(error);
            }
        )
    }

}

export default new AuthenticationService();

