import React, { Component } from "react";
import CookieWallService from "../../api/CookieWallService.js";

// Component for creating footer
class FooterComponent extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="row">
          <div className="col-1-of-3">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuis.nl/"
                >
                  Mediahuis
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuis.nl/adverteren/"
                >
                  Adverteren
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.familieberichten.nl"
                >
                  Familieberichten
                </a>
              </li>
            </ul>
          </div>
          <div className="col-1-of-3">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuis.nl/algemene-voorwaarden/gebruikersvoorwaarden-uitgaven/"
                >
                  Algemene voorwaarden
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuis.nl/privacy-statement/"
                >
                  Privacy Statement
                </a>
              </li>
              <li>
                <button onClick={() => CookieWallService.openPreferences()}>
                  Privacy voorkeuren
                </button>
              </li>
            </ul>
          </div>
          <div className="col-1-of-3">
            <ul>
              <li>
                <a
                  id="bezorging_link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuis.nl/krantgemist"
                >
                  Bezorging
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.mediahuis.nl/cookieverklaring_tcf2-0/"
                >
                  Cookieverklaring
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterComponent;
