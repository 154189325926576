import Axios from "axios";

class ArticlesService {

    /***** API Calls To Backend *****/

    getArticles(url){
        return Axios.get(`${url}`);

        //console.log(`dsadsaddsdsd: ${url}`);
        //return Axios.get(`https://lc.nl/cook/_/most-read?count=5&subSection=false&section=franekercourant`);
    }
}

export default new ArticlesService();

