import {Component} from 'react';
import ReactGA from 'react-ga';

const main = {
    "--header-color": "#AAD16B",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#f2f2f2",
    "--header-button-bg-color-active": "#efefef",
    "--header-button-text-color": "#014A94",
    "--header-button-border-color": "#AAD16B",
  
    "--button-main-color": "#AAD16B",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#014A94",
    "--breadcrumb-underline-color": "#AAD16B",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#AAD16B",
    "--footer-copyright-color": "#014A94"
  };
  
  
  const red_weekblad = {
    "--header-color": "#E63328",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#f2473e",
    "--header-button-bg-color-active": "c62b23",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#E63328",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#E63328",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#E63328",
    "--footer-copyright-color": "#000"
  };
  
  const blue_weekblad = {
    "--header-color": "#0090c2",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#00cbff",
    "--header-button-bg-color-active": "#008aad",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#0090c2",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#0090c2",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#0090c2",
    "--footer-copyright-color": "#014A94"
  };

  const blue_weekblad_2 = {
    "--header-color": "#0090c2",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#00cbff",
    "--header-button-bg-color-active": "#008aad",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#0090c2",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#0090c2",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#0090c2",
    "--footer-copyright-color": "#014A94"
  };

  const blue_nieuwsblad_1 = {
    "--header-color": "#009FCE",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#00cbff",
    "--header-button-bg-color-active": "#008aad",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#009FCE",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#009FCE",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#009FCE",
    "--footer-copyright-color": "#014A94"
  }; 

  const blue_nieuwsblad_2 = {
    "--header-color": "#1299D9",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#00cbff",
    "--header-button-bg-color-active": "#008aad",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#1299D9",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#1299D9",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#1299D9",
    "--footer-copyright-color": "#014A94"
  }; 

  const blue_nieuwsblad_3 = { 
    "--header-color": "#003A8C",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#00cbff",
    "--header-button-bg-color-active": "#008aad",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#003A8C",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#003A8C",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#003A8C",
    "--footer-copyright-color": "#014A94"
  }; 

  const balkster_courant = { 
    "--header-color": "#00AEDA",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#00cbff",
    "--header-button-bg-color-active": "#008aad",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#00AEDA",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#00AEDA",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#93CC65",
    "--footer-copyright-color": "#014A94"
  }; 

  const lc = { 
    "--header-color": "#5059B3",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#00cbff",
    "--header-button-bg-color-active": "#008aad",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#5059B3",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#5059B3",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#5059B3",
    "--footer-copyright-color": "#014A94"
  }; 

  const dvhn = { 
    "--header-color": "#0063AF",
    "--header-title-color": "#000",
  
    "--header-button-bg-color": "#fff",
    "--header-button-bg-color-hover": "#00cbff",
    "--header-button-bg-color-active": "#008aad",
    "--header-button-text-color": "#fff",
    "--header-button-border-color": "#fff",
  
    "--button-main-color": "#0063AF",
    "--button-main-text-color": "#fff",
  
    "--breadcrumb-text-color": "#000",
    "--breadcrumb-underline-color": "#0063AF",
  
    "--footer-bg-color": "#fff",
    "--footer-border-color": "#5059B3",
    "--footer-copyright-color": "#014A94"
  }; 

  class ThemeComponent extends Component  {


    render(){
        var themeConfig = main;

        if(this.props.theme === "blue_weekblad"){
          themeConfig = blue_weekblad;
          ReactGA.initialize('UA-159420853-1');
          ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
        }

        if(this.props.theme === "red_weekblad"){
          themeConfig = red_weekblad;
          ReactGA.initialize('UA-159420853-2');
          ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
      }

        if(this.props.theme === "blue_weekblad_2"){
            themeConfig = blue_weekblad_2;
            ReactGA.initialize('UA-159420853-2');
            ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
        }

        if(this.props.theme === "blue_nieuwsblad_1"){
          themeConfig = blue_nieuwsblad_1;
          ReactGA.initialize('UA-159420853-3');
          ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
        }

        if(this.props.theme === "blue_nieuwsblad_2"){
          themeConfig = blue_nieuwsblad_2;
          ReactGA.initialize('UA-159420853-3');
          ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
        }

        if(this.props.theme === "blue_nieuwsblad_3"){
          themeConfig = blue_nieuwsblad_3;
          ReactGA.initialize('UA-159420853-3');
          ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
        }

        if(this.props.theme === "balkster_courant"){
          themeConfig = balkster_courant;
          ReactGA.initialize('UA-159420853-1');
          ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
        }

        if(this.props.theme === "lc"){
          themeConfig = lc;
          ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
        }

        if(this.props.theme === "dvhn"){
          themeConfig = dvhn;
          ReactGA.pageview(window.location.pathname + window.location.search); // Record a pageview for the given page
        }

        if(this.props.theme === "default"){
          themeConfig = main;
        }

        Object.keys(themeConfig).map(key => {
            const value = themeConfig[key];
            document.documentElement.style.setProperty(key, value);
            return null;
        });

        return null;
      };
  }

  export default ThemeComponent;