import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';

import InfiniteScroll from 'react-infinite-scroller';

import PublicationService from '../../../api/PublicationService.js';
import PublicationUtil from '../../util/PublicationUtil.js';

class PublicationRecentSectionComponent extends Component {

    constructor(props){
        super(props);
        this.state = {
            title : PublicationUtil.getPublicationName(),
            limit: 10,
            recentPubs: [],
            noRecentPubs: false,
            errorRecentPubs: false,
            hasMore: true, 
            pageStart: 0
        }
        this.getNewPublication = this.getNewPublication.bind(this);
        this.loadPublications = this.loadPublications.bind(this);

    }

    render (){
        var items = [];
        if(this.props.pub != null){
            this.state.recentPubs.map((pubDate, pubDateKey) => {
                return items.push(
                    <div className="publication-recent__date" key={pubDateKey}>
                        <h2 className="heading-tertiary"><Moment locale='NL' parse="YYYY-MM-DD" format="dddd DD-MM-YYYY">{pubDate.date}</Moment></h2>
                        {pubDate.publications && pubDate.publications.map((pub, pubKey) =>
                            <div key={pubDateKey + "_" + pubKey}>
                                <div className="publication-recent__date__single" onClick={() => this.getNewPublication(pub)}>
                                    <div className="publication-recent__date__single__cover" > 
                                        <img src={pub.coverImageUri} alt="Recent Publication"></img>
                                    </div>
                                </div>             
                            
                                {pub.annex && pub.annex.map((annex, annexKey) => 
                                    <div className="publication-recent__date__single" onClick={() => this.getNewPublication(annex)} key={pubDateKey + "_" + annexKey}>
                                        <div className="publication-recent__date__single__cover"> 
                                            <img src={annex.coverImageUri} alt="Bijlage"></img>
                                        </div>
                                    </div>
                                )}
                            </div> 
                        )}                          
                   </div>
                );
            })
        }

        return ( 
            <section className="publication-recent">
                <div className="row">                       
                    <h2 className="heading-secondary">Recente publicaties</h2>
                    {(this.props.errorRecentPubs || this.props.error) && <h3 className="heading-quarternary">Geen publicaties beschikbaar</h3>}
                    {this.props.noRecentPubs && <h3 className="heading-quarternary">Geen publicaties beschikbaar</h3>}
                    <div className="publication-recent__container" ref={(ref) => this.scrollParentRef = ref}>                        
                        <InfiniteScroll 
                            pageStart={this.state.pageStart}
                            loadMore={this.loadPublications.bind(this)}
                            hasMore={this.state.hasMore}
                            useWindow={false}
                            getScrollParent={() => this.scrollParentRef}
                            loader={<div className="loader" key={0}>laden ...</div>}
                        >
                            {items}
                        </InfiniteScroll>
                    </div>
                </div>
            </section>              
            
        )
    }

    getNewPublication(pub){
        this.props.getPublication(pub);
        window.scrollTo(0, 0);
    }    

    async loadPublications(page){  
        if(this.state.noRecentPubs === false && this.state.hasMore){
            var newPubs = this.state.recentPubs;
            try {
                let result = await PublicationService.getPublicationsPerPage(page, this.state.limit);

                if(this.props.page === 1 && result.data.publicationsByDate.length < 2){
                    this.setState({
                        noRecentPubs: true,
                        hasMore: false
                    });
                }
                if(result.data.publicationsByDate.length < 1){
                    this.setState({
                        hasMore: false
                    })
                } else {
                    result.data.publicationsByDate.map((pub) => {
                        return newPubs.push(pub);
                    });
                }
                this.setState({
                    noRecentPubs: false,
                    recentPubs: newPubs
                });
            } catch(error) {
                this.setState({
                    errorRecentPubs: true,
                    hasMore: false
                });
                console.log(error)
            }
        }
    }

}

export default withRouter(PublicationRecentSectionComponent);