import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import {NO_LOGON, NO_SUBSCRIPTION, NO_PREMIUM_LEFT} from '../../../constants';

class PublicationSingleSectionComponent extends Component {

    render (){
        return (
            <section className="publication-single">
                {this.props.error && 
                    <h2 className="heading-secondary">Er is een fout opgetreden tijdens het ophalen van de publicatie</h2>
                }

                {!this.props.pub && !this.props.error &&
                    <h2 className="heading-secondary">Deze publicatie is niet beschikbaar</h2>
                }

                {this.props.pub &&
                    <h2 className="heading-secondary"><Moment locale='NL' parse="yyyy-MM-DD'T'HH:mm:ss" format="dddd D MMMM">{this.props.pub.publishedDate}</Moment></h2>
                }
                
                <div className="publication-single__cover"> 
                    { this.props.loggedInError && !this.props.subscriberError && !this.props.premiumAccessError && <h3 className="heading-tertiary publication-single__cover__no-access-title">{NO_LOGON}</h3>}
                    {this.props.subscriberError && !this.props.premiumAccessError && <h3 className="heading-tertiary publication-single__cover__no-access-title">{NO_SUBSCRIPTION}</h3>}
                    {this.props.premiumAccessError && <h3 className="heading-tertiary publication-single__cover__no-access-title">{NO_PREMIUM_LEFT}</h3>}
                    {this.props.pub &&
                    <img className={(this.props.loggedInError || this.props.premiumAccessError || this.props.subscriberError) ? "publication-single__cover__no-access-pub" : "" }
                        src={this.props.pub.coverImageUri} alt="publicatie" onClick={() => this.props.sendToReader(this.props.pub, this.props.pub.readerUrl)}></img>}
                </div>
            </section>
        )
    }

}

export default withRouter(PublicationSingleSectionComponent);