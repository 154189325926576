import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {HOME_URL} from '../../constants';


import AuthenticationService from '../../api/AuthenticationService.js'

import '../../api/EvolokService.js';

import CookieWall from '../util/CookieWall';
import ThemeComponent from '../util/ThemeComponent';
import HomeComponent from './HomeComponent';
import ErrorComponent from './ErrorComponent';


// Component for creating main component
class MainComponent extends Component {


    constructor(props){
        super(props);

        this.loginViaExistingSessionId = this.loginViaExistingSessionId.bind(this);
        this.renewSessionId = this.renewSessionId.bind(this);
    }

    render(){
        return (
            <main id="main-section" className="main-section">
                <CookieWall />
                <ThemeComponent />
                <Switch >
                    <Route path={HOME_URL} component={HomeComponent} />
                    <Route component={ErrorComponent} />
                </Switch>
            </main>
        )
    }

    componentDidMount() {
        if(!AuthenticationService.isUserLoggedIn()){
            this.loginViaExistingSessionId();
        } else {
            setTimeout(function(){
                this.renewSessionId();
            }.bind(this), 1500);           
        } 
    }

     // Login functionality is not activated
    loginViaExistingSessionId(){
        let sessionId = AuthenticationService.getSessionId();
        console.log(sessionId);
        if(sessionId !== undefined){
            AuthenticationService.authenticateBySessionId(sessionId)
            .then(
                (response) => {
                    AuthenticationService.saveToken(response.data.token, response.data.sessionId);
                }
            )
            .catch(
                (error) => {
                   console.log(error);
                   AuthenticationService.logout();
                }
            )
        }
    }

    renewSessionId(){
        AuthenticationService.touchToken();
    }
    
}

export default withRouter(MainComponent);