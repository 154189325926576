import Axios from "axios";
import {READER_API_URL} from '../constants'
import PublicationUtil from '../components/util/PublicationUtil.js'


class ReaderService {

    /***** API Calls To Backend *****/

    sendToReader(id, title, url, publishedDate, day, os){
        return Axios.post(`${PublicationUtil.getHostName() + READER_API_URL}`, {id, title, url, publishedDate, day, os} ,{withCredentials: true});
    }

}

export default new ReaderService();

